<div class="auction-component" [ngClass]="{ 'dx-rtl': rtlEnabled }" dir="{{rtlEnabled ? 'rtl' : 'ltr'}}">
  <dx-popup #addAuction [width]="600" [height]="500" [showTitle]="true" title="{{ modalTitle }}" [dragEnabled]="false"
    [closeOnOutsideClick]="false" [rtlEnabled]="rtlEnabled" [(visible)]="isOpened"
    (onShown)="onFieldDataChanged($event)">
    <dx-scroll-view width="100%" height="100%">
      <div class="au-dx-popup-padder">
        <dx-form #auctionForm id="auctionForm" validationGroup="auctionData" [showValidationSummary]="false"
                 (onFieldDataChanged)="onFieldDataChanged($event)" colCount="auto" [(formData)]="model" [minColWidth]="500">

          <dxi-item itemType="group">
            <dxi-item dataField="name">
              <dxo-label text="{{ 'SHARED.NAME' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'SHARED.NAME' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="percentageLimit" editorType="dxNumberBox">
              <dxo-label text="{{ 'AUCTIONS.PERCENTAGE_LIMIT' | translate }}"></dxo-label>
            </dxi-item>
            <dxi-item dataField="creditLimitCanBeOverruled" editorType="dxCheckBox">
              <dxo-label text="{{ 'AUCTIONS.CREDIT_LIMIT' | translate }}" location="right"></dxo-label>
            </dxi-item>
            <dxi-item dataField="numberOfTransactionsStartPrice" editorType="dxNumberBox">
              <dxo-label text="{{ 'AUCTIONS.NUMBER_OF_TRANSACTIONS_START_PRICE' | translate }}"></dxo-label>
              <dxi-validation-rule type="required" message="{{ 'AUCTIONS.NUMBER_OF_TRANSACTIONS_START_PRICE' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="minTransactionAmountEffectStartPrice" editorType="dxNumberBox">
              <dxo-label text="{{ 'AUCTIONS.MIN_TRANSACTION_AMOUNT_EFFECT_START_PRICE' | translate }}">
              </dxo-label>
              <dxi-validation-rule type="required" message="{{ 'AUCTIONS.MIN_TRANSACTION_AMOUNT_EFFECT_START_PRICE' | translate }} {{ 'SHARED.IS_REQUIRED' | translate }}">
              </dxi-validation-rule>
            </dxi-item>
            <dxi-item dataField="galleryType" editorType="dxSelectBox" [editorOptions]="{ items: galleryTypes, valueExpr:'name', displayExpr: 'name' }" >
              <dxo-label text="{{ 'AUCTIONS.GALLERYTYPE' | translate }}">
              </dxo-label>
            </dxi-item>
            <dxi-item dataField="gallerySize" editorType="dxNumberBox">
              <dxo-label text="{{ 'AUCTIONS.GALLERYSIZE' | translate }}">
              </dxo-label>
            </dxi-item>
          </dxi-item>

        </dx-form>
        <button type="submit" class="btn btn-primary" (click)="onSubmit();" name="btnAuctionSubmit" disabled="disabled">
        {{ 'SHARED.SAVE' | translate }}
        </button>
      <button type="button" class="btn btn-outline" (click)="onCancel();">{{ 'SHARED.CANCEL' | translate }}</button>

        </div>
    </dx-scroll-view>
  </dx-popup>
</div>
