export class Auction {
  auctionId: number;
  auctionClusterId: number;
  name: string;
  isDeleted: boolean;
  percentageLimit: number;
  creditLimitCanBeOverruled: boolean;

  numberOfTransactionsStartPrice: number;
  minTransactionAmountEffectStartPrice: number;

  galleryType: string;
  gallerySize: number;

  // displayable fields:
  auctionClusterDisplayName: string;
}
