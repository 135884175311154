import { Component, ElementRef, HostListener, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ClrTabs } from '@clr/angular';
import dxDataGrid from 'devextreme/ui/data_grid';
import { Subscription } from 'rxjs';

// components
import { DxPopupComponent } from 'devextreme-angular';
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';
import { SaveConfirmationComponent } from '../../shared/components/save-confirmation/save-confirmation.component';

// models
import { Auction } from '../../shared/models/auction';
import { Clock } from '../../shared/models/clock';
import { Currency } from '../../shared/models/currency';

import { AuctionClockPair, AuctionClusterBuyer, BuyerSecondRestrictionMasterData } from '../shared/models/auction-cluster-buyer';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterBuyerService } from '../shared/services/auction-cluster-buyer.service';

const ESC_KEYCODE = 27;

@Component({
  selector: 'buyer-component',
  templateUrl: 'buyer.component.html',
  styleUrls: ['./buyer.component.scss']
})
export class BuyerComponent extends ItemDetailsComponent<AuctionClusterBuyer> implements OnInit, OnDestroy {

  assignAdminUser = false;
  auctionClusterId: number;
  filteredBuyers: Array<AuctionClusterBuyer> = [];
  auctions: Array<Auction> = [];
  clocks: Array<Clock> = [];
  currencies: Array<Currency> = [];
  vatValue: string;
  numberNotUnique: boolean;
  vatValid = true;
  uniqueNumber = true;
  filteredClocks: Array<Clock> = [];
  subNumberNotUnique: boolean = false;
  users = [];
  subBuyerGrid: dxDataGrid;
  subBuyerRowValidationErrors: Array<any> = [];
  pincodeGrid: dxDataGrid;
  secondRestrictionMasterDataName: string;
  masterDataListValues: any = [];
  secondRestrictionTable: dxDataGrid;

  accesses = [];

  formDirty: boolean = false;
  buyersPaginated: any = {};

  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  private _subscription: Subscription;

  @ViewChild('tabs') tabs: ClrTabs;
  @ViewChild('saveConfirmation') saveConfirmation: SaveConfirmationComponent;
  @ViewChild('licenseConfirmation') licenseConfirmation: SaveConfirmationComponent;
  @ViewChild('vat', { read: ElementRef }) vatInput: any;
  @ViewChild(DxPopupComponent) popup: DxPopupComponent;


  constructor(
    protected injector: Injector,
    private languageService: LanguageService,
    private dataService: AuctionClusterBuyerService) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new AuctionClusterBuyer();
    this.setTranslations('BUYER');

    this.accesses = [
      { 'val': true, 'template': 'BUYER.ACCESS_TO_ALL_AUCTIONS' },
      { 'val': false, 'template': 'BUYER.SPECIFIC_ACCESS' },
    ];

  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  translateSelectBoxes = (item) => {
    if (item) {
      return this.getTranslation(item.name);
    }
    return "";
  }

  open(buyers: Array<AuctionClusterBuyer>, id: number, auctions: Array<Auction>, clocks: Array<Clock>, auctionClusterId: number,
    currencies: Array<Currency>, secondRestrictionMasterDataName: string, masterDataListValues: any) {
    this.allItems = buyers;
    this.auctionClusterId = auctionClusterId;
    this.auctions = auctions;
    this.currencies = currencies;
    this.clocks = clocks;
    this.secondRestrictionMasterDataName = secondRestrictionMasterDataName;
    this.masterDataListValues = masterDataListValues;
    this.formDirty = false;

    // Autoselect first tab
    // this.tabs.ifActiveService.current = this.tabs.tabsService.children[0].id;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getBuyer(this.auctionClusterId, id)
        .subscribe((res: AuctionClusterBuyer) => {
          this.model = res;
          this.parseChildObjects();
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.model = new AuctionClusterBuyer();
      this.model.isActiveOnAuctionCluster = true;
      this.isEditMode = false;
      this.isOpened = true;
      this.model.accessToAllAuctionsAndClocks = true;
    }

    this.buyersPaginated = {
      paginate: true,
      pageSize: 20,
      store: this.allItems,
      sort: 'name'
    };
  }

  buyersChange(event: any) {
    const buyerId = this.model.buyerId;

    if (buyerId) {
      const buyer = this.filteredBuyers.find(b => b.buyerId === buyerId);

      if (buyer) {
        this.model.name = buyer.name;
        this.model.vat = buyer.vat;
        this.model.address1 = buyer.address1;
        this.model.address2 = buyer.address2;
        this.model.zip = buyer.zip;
        this.model.city = buyer.city;
        this.model.country = buyer.country;
        this.model.telNumber = buyer.telNumber;
        this.model.faxNumber = buyer.faxNumber;
        this.model.email = buyer.email;
        this.model.url = buyer.url;
        this.model.usedOnOtherAuctionCluster = buyer.usedOnOtherAuctionCluster;
      }
    }
  }

  // verifyVat(vat: string) {
  //   if (this.model.buyerId) {
  //     const currentItem = this.allItems.find(s => {
  //       return s.buyerId === this.model.buyerId;
  //     });
  //     if (currentItem) {
  //       if (currentItem.vat === vat) {
  //         this.vatValid = true;
  //         this.vatInvalid = false;
  //         return;
  //       }
  //     }
  //   } {
  //     this.dataService.checkVat(this.auctionClusterId, vat)
  //       .subscribe(ifExist => {
  //         if (!ifExist) {
  //           this.vatValid = true;
  //           this.vatInvalid = false;
  //           return;
  //         } else {
  //           this.vatInvalid = true;
  //           this.vatValid = false;
  //           return;
  //         }
  //       });
  //   }
  // }

  async save() {
    // Check if datagrid has unsaved data
    if (this.pincodeGrid.hasEditData()) {
      await this.pincodeGrid.saveEditData();
    }

    if (this.subBuyerGrid.hasEditData()) {
      await this.subBuyerGrid.saveEditData();
      if (this.subBuyerRowValidationErrors.length === 0)
        this.saveBuyerData();
    }
    else {
      this.saveBuyerData();
    }
  }

  private saveBuyerData() {
    if (!this.check()) {
      this.errorService.show(this.errorMessage);
      return;
    }
    if (this.model.accessToAllAuctionsAndClocks) {
      this.model.buyerAuctionClocks = [];
    } else {
      this.model.buyerAuctionClocks = this.model.buyerAuctionClocks.filter(role => role.auctionId && role.clockId);
    }

    if (this.secondRestrictionTable) {
      this.secondRestrictionTable.instance().closeEditCell();
      this.secondRestrictionTable.instance().saveEditData();
    }

    this.model.buyerSecondRestrictionMasterData = this.model.buyerSecondRestrictionMasterData.filter(_ => _.masterDataListRowId);

    if (this.model.blockedOnCreditLimit)
      this.model.buyerAuctionClocks.forEach(_ => _.blockedForBuying = true);

    this.spinner.show();
    if (this.model.buyerId) {
      this.dataService.edit(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterBuyer();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            if (error.error && error.error.length > 0 && error.error[0].ErrorMessage === 'BUYER_LICENCE_TOOMANYUSERS') {
              this.licenseConfirmation.message = this.translate.instant('BUYER.BUYER_LICENCE_TOOMANYUSERS', { userList: (error.error[0].PropertyNames as string[]).join(', ') });
              this.licenseConfirmation.opened = true;
            }
            else {
              this.errorService.show(error);
            }
            this.spinner.hide();
          });
    } else {
      this.dataService.save(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new AuctionClusterBuyer();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  saveWithoutClose() {
    // Check if datagrid has unsaved data
    if (this.subBuyerGrid.hasEditData()) {
      this.subBuyerGrid.saveEditData().then(() => {
        this.saveBuyerDataWithoutClose();
      });
    }
    else {
      this.saveBuyerDataWithoutClose();
    }
  }

  private saveBuyerDataWithoutClose() {
    if (!this.check() || !this.model.buyerId) {
      return;
    }
    if (this.model.accessToAllAuctionsAndClocks) {
      this.model.buyerAuctionClocks = [];
    } else {
      this.model.buyerAuctionClocks = this.model.buyerAuctionClocks.filter(role => role.auctionId && role.clockId);
    }

    if (this.model.blockedOnCreditLimit)
      this.model.buyerAuctionClocks.forEach(_ => _.blockedForBuying = true);

    this.spinner.show();
    this.dataService.edit(this.auctionClusterId, this.model)
      .subscribe((res: any) => {
        this.model = res;
        this.errorMessage = null;
        this.spinner.hide();
      },
        error => {
          this.errorService.show(error);
          this.spinner.hide();
        });
  }

  // Parse clockFields
  private parseChildObjects() {

    if (!this.model.buyerAuctionClocks) {
      this.model.buyerAuctionClocks = [];
    } else {
      this.model.buyerAuctionClocks = this.model.buyerAuctionClocks.map(source => {
        const item = new AuctionClockPair();
        item.auctionId = source.auctionId;
        item.clockId = source.clockId;
        item.blockedForBuying = source.blockedForBuying;
        this.filterAuctionClocks(item);
        return item;
      });
    }
  }

  checkBuyerNumber(inputElem: NgForm) {
    const buyer = this.allItems.find(b => b.buyerNumber === this.model.buyerNumber && b.buyerId !== this.model.buyerId);
    if (buyer) {
      this.numberNotUnique = true;
      inputElem.control.setErrors({ 'invalid': true });
    } else {
      this.numberNotUnique = false;
    }
  }

  getTranslation(value: string) {
    return this.languageService.getTranslatableText(value);
  }

  getFilteredClocks = (options) => {
    return {
      store: this.clocks,
    }
  }

  filterAuctionClocks(item: AuctionClockPair) {
    item.filteredClocks = this.clocks.filter(f => f.auctionId === item.auctionId);
  }

  searchByVat() {
    this.dataService.getByVat(this.auctionClusterId, this.vatValue).subscribe((buyers: Array<AuctionClusterBuyer>) => {
      this.filteredBuyers = buyers;
    });
  }

  addClockAccess() {
    const item = new AuctionClockPair();
    this.model.buyerAuctionClocks.push(item);
  }

  deleteAuctionClock(index: number) {
    this.model.buyerAuctionClocks.splice(index, 1);
  }

  private check(): boolean {
    if (!this.checkUniqueSubNumber()) {
      return false;
    }

    if (this.subNumberNotUnique) {
      return false;
    }

    if (!this.checkForDuplicates(this.model.buyerAuctionClocks)) {
      return false;
    }

    if (!this.checkForSecondRestrictionDuplicates(this.model.buyerSecondRestrictionMasterData)) {
      return false;
    }

    if (!this.checkIfClockNull(this.model.buyerAuctionClocks)) {
      return false;
    }

    if (!this.model.accessToAllAuctionsAndClocks && this.model.buyerAuctionClocks.length == 0) {
      this.errorMessage = this.errorService.translations.NO_CLOCK_ACCESS_DEFINED;
      return false;
    }

    return true;
  }

  checkIfClockNull(auctionClocks: Array<AuctionClockPair>): boolean {
    let hasNull = false;

    for (let i = 0; i < auctionClocks.length; i++) {
      if (auctionClocks[i].clockId === null) {
        const elementId = auctionClocks[i].auctionId;
        let count = 0;
        auctionClocks.forEach(item => {
          if (item.auctionId === elementId) {
            count++;
            if (count > 1) {
              this.errorService.show(this.errorService.translations.ALL_CLOCKS_INCLUDE_EVERY_AUCTION);
              hasNull = true;
            }
          }
        });
      }
      else if (auctionClocks[i].clockId === 0) {
        this.errorService.show(this.errorService.translations.ALL_CLOCKS_MUST_HAVE_VALUE);
        hasNull = true;
      }
    }
    return !hasNull;
  }


  checkForDuplicates(auctionClocks: Array<AuctionClockPair>): boolean {
    let isValid = false;

    const testArray = auctionClocks.map((element, index, self) => {
      const i = auctionClocks.findIndex(f => f.auctionId === element.auctionId && f.clockId === element.clockId);
      return index !== i ? element : null;
    }).filter(f => f !== null);

    if (testArray.length !== 0) {
      isValid = true;
      this.errorService.show(this.errorService.translations.TWO_IDENTICAL_ROWS);
    }

    return !isValid;
  }

  checkForSecondRestrictionDuplicates(auctionClocks: Array<BuyerSecondRestrictionMasterData>): boolean {
    let isValid = false;

    const testArray = auctionClocks.map((element, index, self) => {
      const i = auctionClocks.findIndex(f => f.masterDataListRowId === element.masterDataListRowId);
      return index !== i ? element : null;
    }).filter(f => f !== null);

    if (testArray.length !== 0) {
      isValid = true;
      this.errorService.show(this.errorService.translations.TWO_IDENTICAL_ROWS);
    }

    return !isValid;
  }

  onCancel() {
    super.onCancel();
  }

  @HostListener('window:keydown', ['$event'])
  protected handleWindowKeyDownEvent(event: any) {
    if (event.keyCode === ESC_KEYCODE) {
      event.target.blur();
      if (this.formDirty) {
        this.saveConfirmation.opened = true;
      } else {
        this.onCancel();
      }
    }
  }

  validateScope(e: any) {
    if (e.value < 1 || e.value > 999) {
      return false;
    }
    return true;
  }

  checkUniqueSubNumber() {
    const subNotUnique = false;

    // Check if there are subnumbers with same number in array
    const vals = this.model.subbuyers.map(item => item.orderNumber);

    const isNotUnique = vals.some((val, ind) => vals.indexOf(val) != ind);

    if (isNotUnique) {
      this.errorService.show(this.errorService.translations.NOT_UNIQUE_SUBNUMBER);
      this.subNumberNotUnique = true;
    } else {
      this.subNumberNotUnique = false;
    }

    return !subNotUnique;
  }

  checkUniqueNumber() {
    if (this.allItems.find(b => b.buyerNumber === this.model.buyerNumber && b.buyerId !== this.model.buyerId)) {
      this.numberNotUnique = true;
      this.model.buyerNumber = "";
      this.errorService.show(this.errorService.translations.NOT_UNIQUE_NUMBER);
    } else {
      this.numberNotUnique = false;
    }
  }

  onFieldDataChanged(e: any) {
    this.checkUniqueSubNumber();

    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      if (this.numberNotUnique || this.subNumberNotUnique) {
        document.getElementsByName('btnBuyerSubmit')[0].setAttribute('disabled', 'disabled');
      } else if (!this.numberNotUnique && !this.subNumberNotUnique) {
        document.getElementsByName('btnBuyerSubmit')[0].removeAttribute('disabled');
      }
      if (!this.formDirty) this.formDirty = true;
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnBuyerSubmit')[0].removeAttribute('disabled');
      }
    }
  }

  onEditorPreparing = (e: any) => {
    if (
      e.dataField === 'clockId' &&
      e.parentType === 'dataRow'
    ) {
      e.editorOptions.dataSource = {
        loadMode: 'raw',
        load: () => {
          return new Promise((resolve, reject) => {
            e.component
              .getDataSource()
              .store()
              .load()
              .done(gridData => {
                // resolve(this.buyerRoles.filter(f => f.buyerId === e.row.data.buyerId));
                resolve(this.clocks.filter(f => f.auctionId === e.row.data.auctionId));
                // item.filteredClocks = this.clocks.filter(f => f.auctionId === item.auctionId);
              });
          });
        }
      };
    }
  }

  onRowInserted = (e: any) => {
    this.onFieldDataChanged(e);
  }

  onRowUpdated = (e: any) => {
    this.onFieldDataChanged(e);
  }

  onRowRemoved = (e: any) => {
    this.onFieldDataChanged(e);
  }

  onRowValidating = (e: any) => {
    if (!e.isValid)
      this.subBuyerRowValidationErrors.push(e);
  }

  initSubBuyersTable = (e: any) => {
    this.subBuyerGrid = e.component;
  }

  initPincodeTable = (e: any) => {
    this.pincodeGrid = e.component;
  }

  initSecondRestrictionTable = (e: any) => {
    this.secondRestrictionTable = e.component;
  }

  onBuyerClockRowInserted = (e: any) => {
    if (this.model.blockedOnCreditLimit)
      e.data.blockedForBuying = true;
  }

  onBuyerClockInitNewRow = (e: any) => {
    e.data.blockedForBuying = this.model.blockedOnCreditLimit;
  }

  onBlockedOnCreditLimitChanged = (e: any) => {
    this.model.buyerAuctionClocks.forEach(_ => _.blockedForBuying = e.value);
  }

  onBuyerRestrictionInitNewRow = (e: any) => {
    e.data.blocked = false;
  }

  onInitNewPincodeRow = (e: any) => {
    e.data.buyerId = this.model.buyerId;
    e.data.auctionClusterId = this.auctionClusterId;
  }
}
