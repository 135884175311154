export class ArdsInfo {
  clockId: number;
  clockName: string;
  connected: boolean;
  galleryType: string;
  gallerySize: number;
  version: string;
  auctionroomBuyers: Array<AuctionroomBuyer>;
  get connectionStatus(): string {
    return this.connected ? 'Online' : 'Offline';
  
  }
}

export class AuctionroomBuyer {
  buyerId: number;
  seatNumbers: Array<number>;
}

export class GalleryType {
  static readonly None = 'None';
  static readonly RC6 = 'RC6';
  static readonly FortyEightDI = 'FortyEightDI';
  static readonly CTIF = 'CTIF';
  static readonly RC6Simulator = 'RC6Simulator';
}
