import { Component, OnInit, Injector, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

// components
import { ItemDetailsComponent } from '../../shared/components/item-details/item-details.component';

// models
import { Auction } from '../../shared/models/auction';

// services
import { LanguageService } from '../../shared/services/language.service';

import { AuctionClusterAuctionService } from '../shared/services/auction-cluster-auction.service';
import { GalleryType } from '../shared/models/ards-info';


@Component({
  selector: 'auction-component',
  templateUrl: 'auction.component.html',
  styleUrls: ['./auction.component.scss']
})
export class AuctionComponent extends ItemDetailsComponent<Auction> implements OnInit, OnDestroy {

  auctionClusterId: number;
  rtlEnabled = localStorage.getItem('last-selected-language-direction') ? JSON.parse(localStorage.getItem('last-selected-language-direction')) : false;
  galleryTypes: Array<{ id: string, name: string }>;
  private _subscription: Subscription;

  @ViewChild('name', { read: ElementRef }) nameInput: any;

  constructor(
    protected injector: Injector,
    private dataService: AuctionClusterAuctionService,
    private languageService: LanguageService
  ) {
    super(injector);
    this._subscription = this.languageService.direction.subscribe(dir => {
      this.rtlEnabled = dir;
    });
  }

  ngOnInit() {
    this.model = new Auction();
    this.galleryTypes = Object.keys(GalleryType).map(key => ({ id: key, name: GalleryType[key] }));
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  open(auctions: Array<Auction>, id: number, auctionClusterId: number) {
    this.allItems = auctions;
    this.auctionClusterId = auctionClusterId;

    if (id != null) {
      this.isEditMode = true;
      this.spinner.show();
      this.dataService.getAuction(this.auctionClusterId, id)
        .subscribe((res: Auction) => {
          this.model = res;
          this.isOpened = true;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
          this.spinner.hide();
        });
    } else {
      this.model = new Auction();
      this.isEditMode = false;
      this.isOpened = true;
    }

    // setTimeout(() => { this.nameInput.nativeElement.focus(); });
  }

  save() {

    if (this.isEditMode) {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.edit(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Auction();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    } else {
      this.spinner.show();
      this.model.auctionClusterId = this.auctionClusterId;
      this.dataService.save(this.auctionClusterId, this.model)
        .subscribe((res: any) => {
          this.model = new Auction();
          // this.detailsForm.reset();
          this.close(true);
          this.errorMessage = null;
          this.spinner.hide();
        },
          error => {
            this.errorService.show(error);
            this.spinner.hide();
          });
    }
  }

  onFieldDataChanged(e: any) {
    if (e.component._isReady && e.component.NAME !== 'dxPopup') {
      const result = e.component.validate();
      if (result.brokenRules.length >= 1) {
        document.getElementsByName('btnAuctionSubmit')[0].setAttribute('disabled', 'disabled');
      } else {
        document.getElementsByName('btnAuctionSubmit')[0].removeAttribute('disabled');
      }
    } else {
      if (this.isEditMode) {
        document.getElementsByName('btnAuctionSubmit')[0].removeAttribute('disabled');
      }
    }
  }

  returnValues = (e: any) => {
    if (e != null) {
      return e.firstname + ' ' + e.lastname;
    }
  }
}
